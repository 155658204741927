import { FlapperSpinner } from "react-spinners-kit";

export const Loader = () => {
  return (
    <div className="loader-container">
      <FlapperSpinner />
    </div>
  );
};

export const LoaderContainer = () => {
  return (
    <div className="loading-container">
      <FlapperSpinner />
    </div>
  );
};
