import { AxiosResponse } from "axios";
import { useQuery, useQueryClient, useMutation } from "react-query";

// import type { User } from '../../../../../shared/types';
import {
  axiosInstance,
  getJWTHeader,
  axiosInstance2,
  axiosInstance3,
  axiosKyc,
} from "../../../../../axiosInstance";
import { queryKeys } from "../../../../react-query/constants";
import { ErrorToast } from "../../../../componets/toast";
import { hasRoleOAGFAdmin } from "../../../auth/Login";
import { ErrorHandler } from "../../../../../core/helpers";



const host = window.location.host
let kycBaseURL= ''

    if ( host === "https://voxepay.com") {
        kycBaseURL = ' https://bucket.voxepay.com'
      } else {
        kycBaseURL = ' https://bucket.voxepay.app'
      }

async function createSinglePaymentRequest(values: any): Promise<any | null> {
  const orgId: string | null = localStorage.getItem("orgId");
  const token: string | null = localStorage.getItem("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const { data }: AxiosResponse<any> = await axiosInstance2.post(
    `/services/organization/api/organizations/requests/initiate-request/async?request-type=salary_payments`,

    values
  );

  return data;
}

async function createSinglePaymentRequestMESTRO(values: any): Promise<any | null> {
  const orgId: string | null = localStorage.getItem("orgId");
  const token: string | null = localStorage.getItem("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const { data }: AxiosResponse<any> = await axiosInstance2.post(
    `https://api-web.maestrocore.com/api/credit-account`,

    values
  );

  return data;
}

async function doBulKNameVerification(values: any): Promise<any | null> {
  const token: string | null = localStorage.getItem("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const { data }: AxiosResponse<any> = await axiosInstance2.post(`/services/organization/api/account-name-enquiry`, values
  );

  return data;
}

async function getUserBankDetails(): Promise<any | null> {
  const orgId: string | null = localStorage.getItem("orgId");

    const token: string | null = localStorage.getItem("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  if (orgId !== "null") {
    const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
        `/services/organization/api/organizations/${orgId}/banks`
    );
    return data;
  } else {
    return []
  }
}

async function getOrganisationChild(): Promise<any | null> {
  var x: any = localStorage.getItem("lazyday_user");

  var authorities = x !== null ? JSON.parse(x) : null;

  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    `/services/organization/api/organizations/${authorities.mdaCode}/descendants`
  );
  return data;
}

async function getBatchId(): Promise<any | null> {
  const orgId: string | null = localStorage.getItem("orgId");

  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    `/services/organization/api/organizations/requests/generate-batch-id`
  );
  return data;
}

async function getOagfPaymentSummary(filter): Promise<any | null> {
  const token: string | null = localStorage.getItem("token");
  const orgId: string | null = localStorage.getItem("orgId");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    `/api/organizations/total-payment-summary?${filter}`
  );
  return data;
}

async function rejectedTransactions(): Promise<any | null> {
  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    `/services/organization/api/organizations/requests/rejected-requests`
  );
  return data;
}

async function failedTransactions(): Promise<any | null> {
  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    `/services/organization/api/organizations/requests/failed-requests`
  );
  return data;
}

async function getReprocessRequest(value): Promise<any | null> {
  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.post(
    `/services/organization/api/organizations/requests/${value}/rejected-requests`
  );
  return data;
}

async function getReprocessFailedRequest(value): Promise<any | null> {
  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.post(
    `services/organization/api/reprocess-failed-payments/${value}`
  );
  return data;
}

async function getBankList(): Promise<any | null> {
  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    `/services/bankingservice/api/bankingservice/banks`
  );
  return data;
}

async function nameEnquiry(value: any): Promise<any | null> {
  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.post(
    `/services/bankingservice/api/bankingservice/account-enquiry`,
    value
  );
  return data;
}

async function getTotalOutFlow(): Promise<any | null> {
  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    `/services/collection/api/outgoing-funds-total`
  );
  return data;
}

async function getPaymentSummary(filter): Promise<any | null> {
  const token: string | null = localStorage.getItem("token");
  const orgId: string | null = localStorage.getItem("orgId");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    `/services/accountingservice/api/chart-of-accounts/organization/64b6eda93c81863a64592c76`
  );
  return data;
}

async function getMdaPaymentSummary(filter): Promise<any | null> {
  const token: string | null = localStorage.getItem("token");
  const orgId: string | null = localStorage.getItem("orgId");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    `/api/organizations/${orgId}/total-payment-summary?${filter}`
  );
  return data;
}

async function getTotalInFlow(): Promise<any | null> {
  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    `/services/collection/api/payment-total-amount`
  );
  return data;
}

async function getInFlow(filter): Promise<any | null> {
  const orgId: string | null = localStorage.getItem("orgId");
  // `api/organizations/${orgId}/inflow-payment-summary?${filter}`
  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    // `api/organizations/${orgId}/inflow-payment-summary?${filter}`
    `/api/organizations/inflow-payment-summary?${filter}`
  );
  return data;
}

async function getOrgServices(): Promise<any | null> {
  const orgId: string | null = localStorage.getItem("orgId");

  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    `/services/organization/api/organizations/${orgId}/services`
  );
  return data;
}

async function getInFlowByOrgId(orgId): Promise<any | null> {
  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    `/api/organizations/${orgId}/inflow-payment-summary?`
  );
  return data;
}

async function getOutFlow(filter): Promise<any | null> {
  const orgId: string | null = localStorage.getItem("orgId");
  // `/api/organizations/${orgId}/outflow-payment-summary?${filter}`
  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    `/api/organizations/outflow-payment-summary?${filter}`
  );
  return data;
}

async function getOutFlowOafg(filter): Promise<any | null> {
  const orgId: string | null = localStorage.getItem("orgId");

  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    `/api/organizations/outflow-payment-summary?${filter}`
  );
  return data;
}

async function getAvailableBalance(): Promise<any | null> {
  const token: string | null = localStorage.getItem("token");
  const orgId: string | null = localStorage.getItem("orgId");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    `api/organizations/${orgId}/total-payment`
  );
  return data;
}

async function getOagfInFlow(filter): Promise<any | null> {
  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    `/api/organizations/inflow-payment-summary?${filter}`
  );
  return data;
}

async function getBalanceByAccountNumber(
  accountNumber: any
): Promise<any | null> {
  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    `/services/accountingservice/api/ledger-acct-num/${accountNumber}`
  );
  return data;
}

async function getGraphInflowOutflow(): Promise<any | null> {
  const orgId: string | null = localStorage.getItem("orgId");

  if (orgId !== "null") {
    const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
        `/services/accountingservice/api/inflow/outflow/${orgId}`
    );
    return data;
  } else {
    return []
  }


}

async function getGraphInflowOutflowAccountNumber(
  accountNumber: any
): Promise<any | null> {
  const orgId: string | null = localStorage.getItem("orgId");

  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    `/services/accountingservice/api/inflow/outflow/7/${accountNumber}`
  );
  return data;
}



async function getKycStatus(
  entityId: any
): Promise<any | null> {
  const orgId: string | null = localStorage.getItem("orgId");

  const { data }: AxiosResponse<{ user: any }> = await axiosKyc.get(
    `/compliance/api/v1/entity/status/${entityId}`
  );
  return data;
}

async function getEntityDetails(
  entityId: any
): Promise<any | null> {
  const orgId: string | null = localStorage.getItem("orgId");

  const { data }: AxiosResponse<{ user: any }> = await axiosKyc.get(
    `/compliance/api/v1/entity-types/${entityId}`
  );
  return data;
}


async function getOverViewOagf(): Promise<any | null> {
  const token: string | null = localStorage.getItem("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    `/api/general-overview`
  );
  return data;
}

async function getActivityLog(date: any): Promise<any | null> {
  const token: string | null = localStorage.getItem("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    `/services/auditservice/api/event-audit/date/OAgf?${date}`
  );

  return data;
}

async function gettop10Organizations(): Promise<any | null> {
  const token: string | null = localStorage.getItem("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    `/services/organization/api/organizations/top-10-organizations`
  );

  return data;
}

async function gettoptop5mdasectors(): Promise<any | null> {
  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    `/services/organization/api/organizations/top-5-mda-sectors`
  );

  return data;
}

async function gettoptop5mdaService(): Promise<any | null> {
  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    `/services/organization/api/organizations/top-10-revenue-heads?size=5`
  );

  return data;
}

async function getRevenueValidation(): Promise<any | null> {
  const token: string | null = localStorage.getItem("token");
  const orgId: string | null = localStorage.getItem("orgId");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    // `/services/collection/api/payments/${orgId}/organization?size=10000`
    `/services/collection/api/invoices/org/${orgId}?size=10000`
  );

  return data;
}

async function getRevenueValidationOagf(): Promise<any | null> {
  const token: string | null = localStorage.getItem("token");
  const orgId: string | null = localStorage.getItem("orgId");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    `/services/collection/api/payments?size=10000`
  );

  return data;
}

async function setRevenueValidationStatus(
  invoiceNumber: any
): Promise<any | null> {
  const token: string | null = localStorage.getItem("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  var x: any = localStorage.getItem("lazyday_user");

  var authorities = x !== null ? JSON.parse(x) : null;

  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.post(
    // `/services/collection/api/update-service-delivery-status/${invoiceNumber}`,
    `/services/collection/api/invoices/delivery/update/${invoiceNumber}`,
    {
      organizationUserId: authorities?.organizationUser.id,
      fullName: `${authorities?.organizationUser.lastName} ${authorities?.organizationUser.firstName}`,
      orgId: authorities?.id,
      orgName: authorities?.registeredName,
      orgLocation: authorities?.contact.physicalAddress,
    }
  );
  return data;
}

export function useGetAvailableBalance(): any {
  return useMutation(() => getAvailableBalance(), {
    onSuccess: (res) => {},
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useGetOutFlow(): any {
  return useMutation((filter) => getOutFlow(filter), {
    onSuccess: (res) => {},
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useGetOutFlowOagf(): any {
  return useMutation((filter) => getOutFlowOafg(filter), {
    onSuccess: (res) => {},
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useCreateSinglePaymentRequest(): any {
  return useMutation((value) => createSinglePaymentRequest(value), {
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useCreateSinglePaymentRequestMESTRO(): any {
  return useMutation((value) => createSinglePaymentRequestMESTRO(value), {
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useDoBulKNameVerification(): any {
  return useMutation((value) => doBulKNameVerification(value), {
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useGetUserBankDetails(): any {
  return useMutation(() => getUserBankDetails(), {
    onSuccess: (res) => {},
    onError: (err: any) => {
    //  ErrorHandler(err);
    },
  });
}

export function useGetOrganisationChild(): any {
  return useMutation(() => getOrganisationChild(), {
    onSuccess: (res) => {},
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useGetBatchId(): any {
  return useMutation(() => getBatchId(), {
    onSuccess: (res) => {},
    onError: (err: any) => {
    //  ErrorHandler(err);
    },
  });
}

export function useGetBankList(): any {
  return useMutation(() => getBankList(), {
    onSuccess: (res) => {},
    onError: (err: any) => {
    //  ErrorHandler(err);
    },
  });
}

export function useNameEnquiry(): any {
  return useMutation((value) => nameEnquiry(value), {
    onSuccess: (res) => {},
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useGetTotalOutFlow(): any {
  return useMutation(() => getTotalOutFlow(), {
    onSuccess: (res) => {},
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useGetTotalInFlow(): any {
  return useMutation(() => getTotalInFlow(), {
    onSuccess: (res) => {},
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useGetInFlow(): any {
  return useMutation((filter) => getInFlow(filter), {
    onSuccess: (res) => {},
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useGetInFlowyOrgId(): any {
  return useMutation((orgId) => getInFlowByOrgId(orgId), {
    onSuccess: (res) => {},
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useGetBalanceByAccountNumber(): any {
  return useMutation(
    (accountNumber) => getBalanceByAccountNumber(accountNumber),
    {
      onSuccess: (res) => {
        //console.log(res);
      },
      onError: (err: any) => {
       ErrorHandler(err);
      },
    }
  );
}

export function useGetGraphInflowOutflow(): any {
  return useMutation(() => getGraphInflowOutflow(), {
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useGetGraphInflowOutflowAccountNumber(): any {
  return useMutation(
    (accountNumber) => getGraphInflowOutflowAccountNumber(accountNumber),
    {
      onSuccess: (res) => {
        //console.log(res);
      },
      onError: (err: any) => {
       ErrorHandler(err);
      },
    }
  );
}


export function useGetCheckKycStatus(): any {
  return useMutation(
    (entityId) => getKycStatus(entityId),
    {
      onSuccess: (res) => {
        //console.log(res);
      },
      onError: (err: any) => {
       ErrorHandler(err);
      },
    }
  );
}

export function useGetEntityDetails(): any {
  return useMutation(
    (entityId) => getEntityDetails(entityId),
    {
      onSuccess: (res) => {
        //console.log(res);
      },
      onError: (err: any) => {
       ErrorHandler(err);
      },
    }
  );
}

export function useGetOrgServices(): any {
  return useMutation(() => getOrgServices(), {
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useGetOverViewOagf(): any {
  return useMutation(() => getOverViewOagf(), {
    onSuccess: (res) => {
      // console.log(res);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useGetActivityLog(): any {
  return useMutation((date) => getActivityLog(date), {
    onSuccess: (res) => {
      // console.log(res);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useGettop10Organizations(): any {
  return useMutation(() => gettop10Organizations(), {
    onSuccess: (res) => {
      // console.log(res);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useGettoptop5mdasectors(): any {
  return useMutation(() => gettoptop5mdasectors(), {
    onSuccess: (res) => {
      // console.log(res);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useGettoptop5mdaServices(): any {
  return useMutation(() => gettoptop5mdaService(), {
    onSuccess: (res) => {
      // console.log(res);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useGetRevenueValidation(): any {
  const x: any = localStorage.getItem("lazyday_user");
  const authorities = x !== null ? JSON.parse(x) : null;
  const isAdmin = hasRoleOAGFAdmin(authorities?.organizationUser?.authorities);

  return useMutation(
    () => (isAdmin ? getRevenueValidationOagf() : getRevenueValidation()),
    {
      onSuccess: (res) => {
        // console.log(res);
      },
      onError: (err: any) => {
       ErrorHandler(err);
      },
    }
  );
}

export function useSetRevenueValidationStatus(): any {
  return useMutation(
    (receiptNumber) => setRevenueValidationStatus(receiptNumber),
    {
      onSuccess: (res) => {},
      onError: (err: any) => {
       ErrorHandler(err);
      },
    }
  );
}

export function useGetOagfInFlow(): any {
  return useMutation((filter) => getOagfInFlow(filter), {
    onSuccess: (res) => {},
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function usePaymentSummary(): any {
  return useMutation((filter) => getPaymentSummary(filter), {
    onSuccess: (res) => {
      // console.log('demo\n', res);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useMdaPaymentSummary(): any {
  return useMutation((filter) => getMdaPaymentSummary(filter), {
    onSuccess: (res) => {
      // console.log('demo\n', res);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useOagfPaymentSummary(): any {
  return useMutation((filter) => getOagfPaymentSummary(filter), {
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useGetRejectedTransactions(): any {
  return useMutation(() => rejectedTransactions(), {
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useGetFailedTransactions(): any {
  return useMutation(() => failedTransactions(), {
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useReprocessRequest(): any {
  return useMutation((value) => getReprocessRequest(value), {
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}

export function useReprocessFailedRequest(): any {
  return useMutation((value) => getReprocessFailedRequest(value), {
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err: any) => {
     ErrorHandler(err);
    },
  });
}
